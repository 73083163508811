import firebase from 'firebase/app'

firebase.initializeApp({
	apiKey: 'AIzaSyAQZ6IKugJnZlUSW9oMuCLuaz4IsR1bnGA',
	authDomain: 'glaze-chat.firebaseapp.com',
	databaseURL: 'https://glaze-chat.firebaseio.com',
	projectId: 'glaze-chat',
	storageBucket: 'glaze-chat.appspot.com',
	messagingSenderId: '41756056504',
	appId: '1:41756056504:web:22bd9be4a5064e68ad8d4d',
	measurementId: 'G-N8NWR2TC85'
})

export default firebase
